import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/src/templates/layout.js";
import Helmet from 'react-helmet';
import { InputButton } from 'bricks';
import SEO from './../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Careers" description="We don't offer jobs. We offer CAREERS." keywords={["ruby on rails", "Ruby on Rails development", "angularjs framework", "angularjs code", "meteor js", "react js", "reactjs native", "node js development", "node js website", "spree commerce", "ruby on rails developer", "react js components", "javascript meteor", "Angular javascript", "angular framework", "js angular", "angular website", "Android Website", "ios app development", "mobile web app", "javascript android app", "web application development company"]} url="https://codebrahma.com/careers" mdxType="SEO" />
    <h1 {...{
      "id": "careers"
    }}>{`Careers`}</h1>
    <p>{`We don't offer jobs. We offer CAREERS.`}</p>
    <h2 {...{
      "id": "life--codebrahma"
    }}>{`Life @ Codebrahma`}</h2>
    <br />
    <blockquote>
  "If you have thirst to learn, we have ocean of knowledge to quench your thirst."
    </blockquote>
    <p>{`Codebrahma not only provides plethora of opportunities to learn, it provides a platform to be the Brahma of your capabilities. We enjoy the autonomy at work place which makes us responsible for building something from scratch till the end. Each of us is capable of being a one man army in the battlefield yet we are a closely knit team, engaged in building software that leaves its footprints on the world map.`}</p>
    <div className='embed-wrapper'>
  <div className='embed-container'>
    <iframe src="https://player.vimeo.com/video/157740480" frameBorder="0" allow="autoplay; fullscreen" style={{
          "maxWidth": "100%"
        }} allowFullScreen />
  </div>
    </div>
    <h2 {...{
      "id": "interested-in-joining-us"
    }}>{`Interested in Joining Us?`}</h2>
    <h4 {...{
      "id": "contribute-on-our-github-repos-and-win-a-chance-to-get-hired"
    }}>{`Contribute on our `}<a parentName="h4" {...{
        "href": "https://github.com/Codebrahma/react-lite-ui/issues"
      }}>{`GitHub repos`}</a>{` and win a chance to get hired`}</h4>
    <h5 {...{
      "id": "your-effort-is-not-going-waste-you-can-win-bounties-up-to-rs10000"
    }}>{`Your effort is not going waste. You can win bounties up to Rs.10,000.`}</h5>
    <h2 {...{
      "id": "want-to-apply"
    }}>{`Want to Apply?`}</h2>
    <p><em parentName="p">{`Do you want to build world class applications used by millions of people? `}<a parentName="em" {...{
          "href": "https://angel.co/codebrahma/jobs"
        }}>{`Apply Now`}</a></em></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      